import * as React from 'react'

import { graphql } from 'gatsby';

import Layout from '../components/Layout'
import Seo from '../components/Seo';

const CustomerPage = ({ data }) => {

  return (
    <Layout
      currentMenuItem='customer'
    >
      <Seo
        title={'Kunden'}
        description={'Schau dir ausgewählte Premium-Kunden an und erfahre mehr darüber, was Ihre Ausbildung besonders macht'}
      />
      <div className="mx-auto bg-grey-light dark:bg-grey-dark rounded-xl shadow-md overflow-hidden md:max-w-2xl">
        <div
          className="h-full"
        >
          <div
            className="p-6 mb-2 rounded-xl bg-grey-light dark:bg-grey-dark text-text-light dark:text-text-dark"
          >
            <div dangerouslySetInnerHTML={{ __html: data.markdown.childMarkdownRemark.html }} />
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default CustomerPage;

export const query = graphql`
  query ($language: String!){
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    },
    markdown: file(name: {eq: "Customer"}) {
      childMarkdownRemark {
        html
      }
    }
  }
`;